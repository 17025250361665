import { useRouter } from "next/router";
import { Result, Button } from "antd";

const Custom404 = () => {
  const router = useRouter();
  const BacktoHomepage = () => {
    router.push("/schedule");
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      className="mt-5"
      extra={
        <Button type="primary" onClick={BacktoHomepage}>
          Back
        </Button>
      }
    />
  );
};

export default Custom404;
